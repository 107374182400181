<template>
  <div class="productcontent">
    <a class="d_wrap" v-if="productInfo.view3DUrl"
      target="_blank" :href="productInfo.view3DUrl">
      <img src="../../assets/img/3dgif.gif" >
      <p>3D展示</p>
    </a>
    <div class="product-detail">
      <div class="detail" v-if="productInfo">
        <!-- 产品预览-->
        <div class="preview">
          <div v-if="previewUrl" style="height: 400px">
            <a :href="productInfo.panoramaUrl"
              v-if="productInfo.panoramaUrl" target="_blank"
              class="qj_wrap preview-img"
              v-show="true&&previewUrl=='https://img.zhanshangxiu.com/showonline/file/202011/20201106/8f0207f652c74460b58401815de2be92.jpg'">
              <div class="qj_icon">
                <div class="qj_div">
                  <i
                    class="iconfont icon-yuanxitubiao-"></i>
                </div>
              </div>
              <div>
                <iframe id="qj_iframe" name="qj_iframe"
                  width="400" height="400" frameborder="0"
                  :src="productInfo.panoramaUrl"
                  marginwidth="0" marginheight="0"
                  vspace="0" hspace="0"
                  allowtransparency="true" scrolling="no"
                  allowfullscreen="true"></iframe>
              </div>
            </a>
            <videoPlayer :url="productInfo.video.url"
              :cover="productInfo.video.cover"
              :time="productInfo.video.duration"
              ref="videoPlays" @onPlayerPlay="player" v-if="
                 productInfo.video&&
                previewUrl === productInfo.video.cover &&
                productInfo.video.url != ''
              " class="preview-img"></videoPlayer>

            <img :src="previewUrl" class="preview-img"
              v-else-if="previewUrl!='https://img.zhanshangxiu.com/showonline/file/202011/20201106/8f0207f652c74460b58401815de2be92.jpg'" />
          </div>
          <div v-else>
            <img src="../../assets/img/empty.png"
              class="preview-img" />
          </div>
          <div class="spec-list" v-if="imgList">
            <div class="zuo" @click="left">
              <i class="iconfont icon-arr-left icon-l"></i>
            </div>
            <div class="zuo" style="right: 0"
              @click="right">
              <i class="iconfont icon-arr-right icon-r"></i>
            </div>
            <div style="
                position: relative;
                width: 340px;
                height: 60px;
                overflow: hidden;
                margin: 0 auto;
              ">
              <ul class="ul-list" :class="[
                  nextleft ? 'is-left' : '',
                  nextright ? 'is-right' : '',
                ]">
                <li class="li"
                  v-for="(item, index) in imgList"
                  :key="index">
                  <img :src="item" width="60px"
                    height="60px"
                    @mouseover="mouseOver(item, index)"
                    :class="imghover == index ? 'is-hover' : ''" />
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div class="dianzan">
              <div class="icon1 icon_wrap ok"
                @click="deProLove" v-if="isFollow">
                <i class="iconfont icon-love"></i>
                <span>{{ productInfo.followCount }}人关注</span>
              </div>
              <div class="icon1 icon_wrap"
                @click="addProLove" v-else>
                <i class="iconfont icon-love"></i>
                <span>{{ productInfo.followCount }}
                  人关注</span>
              </div>
              <div class="icon2 icon_wrap"
                @click="addproductLike"
                :class="isLike ? 'ok' : ''">
                <i class="iconfont icon-like"></i>
                <span>{{ productInfo.likeCount }} 人点赞</span>
              </div>
            </div>
          </div>
        </div>
        <div class="product-content">
          <div class="product-name">
            {{ productInfo.name }}
          </div>
          <!--卖点-->
          <!-- <div class="product-selling">
            {{ productInfo.selling }}
          </div> -->
          <div class="product-category"
            v-if="productInfo.category">
            <i class="iconfont icon-biaoqian1"></i>
            <span>{{ productInfo.category }}</span>
          </div>
          <div class="product-tips">
            <span v-for="(item, index) in productInfo.tag"
              :key="index">{{
              item
            }}</span>
          </div>
          <!--统计-->
          <div >
            <div class="tongji">
              <div style="text-align: center">
                <div class="span1">
                  {{ productInfo.exhibitor.advisoryCount }}
                </div>
                <div class="span2">咨询</div>
              </div>
              <div class="fenge"></div>
              <div style="text-align: center">
                <div class="span1">
                  {{ productInfo.viewCount }}
                </div>
                <div class="span2">浏览</div>
              </div>
              <div class="fenge"></div>
              <div style="text-align: center">
                <div class="span1">
                  {{ visitOpt.totalCount }}
                </div>
                <div class="span2">访客</div>
              </div>
              <div class="fenge"></div>
              <div style="text-align: center">
                <div class="span1">
                  {{ productInfo.evaluateCount }}
                </div>
                <div class="span2">评价</div>
              </div>
              <div class="fenge"></div>
              <!-- <div style="text-align: center">
                <div class="span1">
                  {{ productInfo.appointmentCount ? "" : 0 }}
                </div>
                <div class="span2">洽谈</div>
              </div> -->
              <div style="text-align: center">
                <div class="span1">
                  {{ productInfo.advisoryCount ? productInfo.advisoryCount : 0 }}
                </div>
                <div class="span2">询盘</div>
              </div>
            </div>
          </div>
          <!--在线预览按钮-->
          <div class="bottom-btn">
            <div class="u-btn-zixun" @click="onLineTalk" v-if="productInfo.exhibitor.customerServiceIsAppointmentNumber">
              <i class="iconfont icon-ziyuan"
                style="margin-right: 10px"></i>
              <span>在线咨询</span>
            </div>
            <div class="u-btn-zixun hui"  v-else>
              <i class="iconfont icon-ziyuan"
                style="margin-right: 10px"></i>
              <span>在线咨询</span>
            </div>
            <div class="u-btn-xunpan" @click="inquiryOpen">
              <i class="iconfont icon-xunpan"
                style="margin-right: 10px"></i>
              <span>询盘</span>
            </div>
            <!-- <div class="u-btn-yuyue" @click="orderOpen">
              <i class="iconfont icon-yuyue1"
                style="margin-right: 10px"></i>
              <span>预约洽谈</span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="detail-r" v-if="company">
      <div style="padding: 0 20px">
        <div class="top">
          <div class="logo">
            <img :src="company.logo" v-if="company.logo" @click="$router.push('exhibitor-detail/' +productInfo.exhibitor.id +'?exhibitionId=' +exhibitionId +'')" v-load="'oPic'" />
            <img :src="productInfo.exhibitor.exhibition.logo" v-else @click="$router.push('exhibitor-detail/' +productInfo.exhibitor.id +'?exhibitionId=' +exhibitionId +'')" v-load="'oPic'" />
          </div>
          <div style="margin-left: 10px">
            <div class="company-name" @click="
                $router.push(
                  'exhibitor-detail/' +
                    productInfo.exhibitor.id +
                    '?exhibitionId=' +
                    exhibitionId +
                    ''
                )
              ">
              {{ company.name }}
            </div>
            <div style="display: flex; margin-top: 5px">
              <div class="u-booth">
                <i class="iconfont icon-dizhi icon" 
                  v-if="productInfo.exhibitor.boothInfo">
                </i>
                <span style="margin-left: 4px" @click="Totour(productInfo.exhibitor)">{{
                  productInfo.exhibitor.boothInfo
                }}</span>
              </div>
              <div class="u-category">
                <i class="iconfont icon-biaoqian1 icon"
                  
                  v-if="productInfo.exhibitor.category">
                </i>
                <span
                  >{{ productInfo.exhibitor.category }}</span>
              </div>
            </div>

            <div class="product-renzheng"
              v-if="productInfo.exhibitor.continuouNumber">
              <div style="
                  background-color: #fff;
                  display: flex;
                  align-items: center;
                  width: 20px;
                  height: 20px;
                  justify-content: center;
                ">
                <img src="@/assets/img/jinpai.png"
                  v-if="productInfo.exhibitor.continuouNumber > 7" />
                <img src="@/assets/img/tongpai.png"
                  v-else-if="productInfo.exhibitor.continuouNumber < 5" />
                <img src="@/assets/img/yinpai.png" v-else />
              </div>
              <span style="
                  display: inline-block;
                  margin-left: 6px;
                  font-size: 12px;
                  color: #ff5e00;
                  paddgin-right: 3px;
                ">
                连续{{ productInfo.exhibitor.continuouNumber }}年参展
              </span>
            </div>
          </div>
        </div>
        <div class="center">
          <div style="font-size: 14px">
            <div style="color: #ff5e00">
              {{ productInfo.exhibitor.productNumber }}
            </div>
            <div>展品</div>
          </div>
          <div style="font-size: 14px">
            <div style="color: #ff5e00">
              {{ productInfo.exhibitor.activityNumber }}
            </div>
            <div>活动</div>
          </div>
        </div>
        <div style="
            width: 280px;
            height: 1px;
            background-color: #f2f2f2;
            margin-top: 20px;
          "></div>
        <div class="code">
          <div id="qrcode" class="qrcode"></div>
          <span
            style="margin-top: 6px; display: inline-block">扫码查看移动端</span>
        </div>
      </div>
    </div>
    <inquiryModal  ref="inquiryModal" ></inquiryModal>
    <!-- <orderModal :exhibitorId="productInfo.exhibitor.id"
      ref="orderModal"></orderModal> -->
  </div>
</template>

<script>
import videoPlayer from "@components/videoPlayer/videoPlayer.vue";
import QRCode from "qrcodejs2";
import { mapActions, mapGetters } from "vuex";
import orderModal from "@components/orderModal";
import inquiryModal from "@components/inquiryModal";
import url from "@/utils/url";

export default {
  name: "index",
  props: {
    productInfo: {},
    visitOpt: {},
  },
  data () {
    return {
      url:url,
      imgList: [],
      previewUrl: "",
      company: "",
      isFollow: false,
      isLike: false,
      nextleft: false,
      nextright: false,
      imghover: "",
    };
  },
  components: {
    videoPlayer,
    orderModal,
    inquiryModal
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      userId: "getUserId",
      userInfo: "getUser",
      myAdvisorySetting:'getMyAdvisorySetting'
    }),
    isRegOk () {
      return (
        this.userInfo &&
        this.userInfo.inMember &&
        this.userInfo.inMember.userRole &&
        this.userInfo.inMember.userRole != "VISITOR" &&
        this.userInfo.inMember.userRole != "Visitor"
      );
    },
  },
  created () {
    this.init();
    if (this.userInfo.userId) {
      this.getFollowAndLikeState();
    }
  },
  mounted () {
    setTimeout(()=>{
       this.getQrCode();
    },500)
   
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
      ProductLike: "product/ProductLike",
      ProductFollow: "product/ProductFollow",
      unverifiedDialog: "unverifiedDialog",
      linkToTour:'linkTo/linkToTour',
          
    }),
    async getFollowAndLikeState () {
      let query = `
          query{
                productFollowQuery{
                isFollow(productId:"${this.productInfo.id}",userId:"${this.userId}")
              }
                productLikeQuery{
                isLike(productId:"${this.productInfo.id}",userId:"${this.userId}")
            }
        }
        `;
      let opt = {
        query: query,
        variables: {},
      };

      let data = await this.graphqlPost(opt);
      console.log(data, 'data展商')
      // this.isFollower=this.exInfo.isFollow;
      let res = JSON.parse(JSON.stringify(data.data));
      this.isFollow = res.productFollowQuery.isFollow;
      this.isLike = res.productLikeQuery.isLike;
    },
    onLineTalk(){
      if(!this.isRegOk){
        this.unverifiedDialog(this);
        return;
      }
      if(this.userInfo&&this.userInfo.inMember.isBlacklist){
        this.$Message.warning('您已被屏蔽');
        return 
      }
      if(!this.getRoleHandle('L1ExShow-L2IM-L3IM')){
        return 
      }
      this.$emit('onLineTalk');
    },
    Totour(item){
      this.linkToTour()
      this.$store.commit('setExhibitor',item)
    },
    init () {
      console.log(this.productInfo, 'zhanpinxiangqing')
      if (this.productInfo) {
        this.imgList = this.productInfo.banner;
        if (this.productInfo.video && this.productInfo.video.cover) {
          this.imgList.unshift(this.productInfo.video.cover);
          this.previewUrl = this.productInfo.video.cover;
        } else {
          this.previewUrl = this.productInfo.banner[0];
        }
        if (this.productInfo.panoramaUrl) {
          this.imgList.unshift('https://img.zhanshangxiu.com/showonline/file/202011/20201106/8f0207f652c74460b58401815de2be92.jpg');
          this.previewUrl = 'https://img.zhanshangxiu.com/showonline/file/202011/20201106/8f0207f652c74460b58401815de2be92.jpg'
        }
        console.log(this.imgList, 'this.imgList')
        this.company = this.productInfo.exhibitor.company;
         console.log(this.company, 'this.company')
      }
    },
    //向左滑动
    left () {
      if (this.imgList.length > 5) {
        this.nextleft = false;
        this.nextright = true;
      }
    },
    //向右滑动
    right () {
      if (this.imgList.length > 5) {
        this.nextleft = true;
        this.nextright = false;
      }
    },
    async inquiryOpen(){
      if (!this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      if(this.userInfo&&this.userInfo.inMember.isBlacklist){
        this.$Message.warning('您已被屏蔽');
        return 
      }
      if(!this.getRoleHandle('L1Operate-L2XunPan-L3Publish')){
        return 
      }
      if(!this.myAdvisorySetting||this.myAdvisorySetting.enquiryDayNumber==0){
        await this.getUserAdvisorySetting()
      }
      if(this.myAdvisorySetting.enquiryDayNumber>0){
        let opt={
          exhibitorId:this.productInfo.exhibitor.id,
          productName:this.productInfo.name,
          productId:this.productInfo.id,
        }
        this.$refs.inquiryModal.open(opt);
      }else{
        this.$Message.warning('您今天的询盘次数已达上限')
      }
      
    },
    //模态框打开
    orderOpen () {
      if (!this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      this.$refs.orderModal.open();
    },
    // 判断视频是否在播放
    player (autoplay) {
      this.autoplay = autoplay;
      console.log(autoplay, "autoplay");
    },
    mouseOver (img, index) {
      this.previewUrl = img;
      this.imghover = index;
      console.log(img, "img");
    },
    getQrCode () {
      let url =
        `https://${this.url.mobile}/product-detail?id=` + this.productInfo.id;
      let qrcode = new QRCode("qrcode", {
        width: 180,
        height: 180,
        text: url, // 二维码内容
        correctLevel: 3,
        // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        // background: '#f0f',   // 背景色
        // foreground: '#ff0'    // 前景色
      });
    },
    //产品点赞
    async addproductLike () {
      if (!this.userInfo.userId) {
        this.unverifiedDialog(this);
        return;
      }
      if (this.isLike) {
        this.$Message.info("您已经点过赞啦");
        return;
      }
      let userInfo = {
        name: this.userInfo.name,
        avatarUrl: this.userInfo.avatarUrl,
        nickName: this.userInfo.nickName,
      };
      let opt = {
        productId: this.productInfo.id,
        exhibitionId: this.exhibitionId,
        exhibitorId: this.productInfo.exhibitor.id,
        userId: this.userInfo.userId,
        userInfo: JSON.stringify(userInfo),
      };
      let result = await this.ProductLike(opt);
      if (result.result) {
        this.isLike = true;
        this.$Message.success("已点赞");
        this.productInfo.likeCount = this.productInfo.likeCount + 1;
      } else {
        this.$Message.error(result.message);
      }
    },
    //关注产品
    async addProLove () {
      if (!this.userInfo.userId) {
        this.unverifiedDialog(this);
        return;
      }
      let userInfo = {
        name: this.userInfo.name,
        avatarUrl: this.userInfo.avatarUrl,
        nickName: this.userInfo.nickName,
      };
      let opt = {
        productId: this.productInfo.id,
        exhibitionId: this.exhibitionId,
        exhibitorId: this.productInfo.exhibitor.id,
        userId: this.userInfo.userId,
        userInfo: JSON.stringify(userInfo),
      };
      let result = await this.ProductFollow(opt);
      if (result.message == "已关注") {
        this.isFollow = true;
        this.$Message.success("已关注");
        this.productInfo.followCount = this.productInfo.followCount + 1;
      } else {
        this.isFollow = false;
        this.$Message.error(result.message);
      }
    },
    //关注产品
    async deProLove () {
      if (!this.userInfo.userId) {
        this.unverifiedDialog(this);
        return;
      }
      // 取消关注
      let body = {
        productId: this.productInfo.id,
        userId: this.userInfo.userId,
      };
      let result = await this.ProductFollow(body);
      if (result.message == "已取消关注") {
        this.isFollow = false;
        this.$Message.success("取消关注");
        this.productInfo.followCount = this.productInfo.followCount - 1;
      } else {
        this.isFollow = true;
        this.$Message.error(result.message);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
::v-deep {
  .vjs-fluid {
    height: 100%;
  }
}
.productcontent {
  margin: 20px auto;
  width: 1200px;
  display: flex;
  background-color: #fff;
  position: relative;
  .d_wrap {
    position: fixed;
    top: 480px;
    right: 20px;
    width: 120px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 14px 28px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    z-index: 4;
    img {
      width: 64px;
      height: 64px;
      display: block;
    }
    p {
      font-size: 16px;
      color: #333;
    }
  }
}

.product-detail {
  width: 880px;
  min-height: 543px;

  background-color: #fff;
}

.detail {
  padding: 20px;
  display: flex;
}

.preview {
  width: 400px;
  height: 400px;

  .preview-img {
    width: 400px;
    height: 400px;
    &.qj_wrap {
      display: block;
      cursor: pointer;
      position: relative;
      .qj_icon {
        background: rgba($color: #000, $alpha: 0.1);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .qj_div {
          background: rgba($color: #000, $alpha: 0.4);
          border-radius: 50%;
          height: 80px;
          width: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            color: #fff;
            font-size: 48px;
          }
        }
      }
      position: relative;
    }
  }

  ul li {
    float: left;
    margin: 0 5px;
    cursor: pointer;
    width: 60px;
    height: 60px;
  }

  .li img {
    &.is-hover {
      border: 2px solid #e53e41;
    }
  }

  .spec-list {
    position: relative;
    margin-top: 10px;
  }

  .icon-l {
    position: absolute;
    left: 0;
    font-size: 17px;
  }

  .icon-r {
    position: absolute;
    right: 0;
    font-size: 17px;
  }

  .zuo {
    display: block;
    width: 22px;
    height: 32px;
    float: left;
    position: absolute;
    cursor: pointer;
    top: 50%;
    margin-top: -14px;
  }

  .ul-list {
    position: absolute;
    width: 740px;
    height: 60px;
    top: 0px;
    left: 0px;
    list-style: none;
  }

  .is-left {
    @keyframes mymove-l {
      from {
        left: 0px;
      }
      to {
        left: -350px;
      }
    }
    animation: mymove-l 2s;
    animation-fill-mode: forwards;
  }

  .is-right {
    animation: mymove-r 2s;
    animation-fill-mode: forwards;

    @keyframes mymove-r {
      from {
        left: -350px;
      }
      to {
        left: 0px;
      }
    }
  }

  .dianzan {
    margin-top: 20px;
    display: flex;
    align-items: center;

    &is-active {
      color: #ff5c00;
    }

    span {
      font-size: 12px;
      color: #666;
    }

    .icon1 {
      margin-right: 20px;

      .icon-love {
        margin-right: 5px;
      }
    }

    .icon2 {
      .icon-like {
        margin-right: 5px;
      }
    }
  }
}

.product-content {
  margin-left: 20px;

  .product-name {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }

  .product-selling {
    font-size: 14px;
    color: #666;
    margin-top: 15px;
  }

  .product-category {
    font-size: 14px;
    color: #333;
    display: flex;
    align-items: center;
    margin-top: 36px;

    i {
      @include font_color(#1890ff);
      margin-right: 5px;
    }
  }

  .product-tips {
    margin-top: 20px;
    margin-left: -10px;

    span {
      color: #ff5c00;
      background-color: #ffeee5;
      padding: 5px 6px;
      border-radius: 3px;
      margin-left: 10px;
      display: inline-block;
      margin-bottom: 10px;
    }
  }

  .tongji {
    padding: 20px 30px 16px 30px;
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    border-radius: 5px;
    height: 70px;
    margin-top: 10px;
    justify-content: center;
    .span1 {
      font-size: 18px;
      color: #ff5c00;
    }

    .span2 {
      font-size: 14px;
      @include font_color(#1890ff);
    }

    .fenge {
      width: 1px;
      height: 26px;
      background-color: #dddddd;
      margin: 0 22px;
    }
  }

  .bottom-btn {
    display: flex;
    margin-top: 40px;
    .xian{
      
    }
  }
}

.detail-r {
  border-left: 1px solid #ededed;
  height: 543px;
  width: 300px;

  .top {
    display: flex;
    padding-top: 20px;
    width: 280px;

    .logo {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      img {
        width: 78px;
        height: 78px;
        cursor: pointer;
        display: block;
      }
    }

    .company-name {
      font-size: 14px;
      color: #333;
      cursor: pointer;
    }
    .u-booth {
      display: flex;
      align-items: center;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 70px;
      }
    }
    .u-category {
      margin-left: 12px;
      display: flex;
      align-items: center;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 70px;
      }
    }
    .product-renzheng {
      height: 22px;
      border: solid 1px #ffe7d9;
      margin-top: 7px;
      display: flex;
      align-items: center;
      background-color: #ffe7d9;
      width: fit-content;
      padding-right: 3px;
    }
  }

  .center {
    width: 146px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-top: 15px;
  }

  .code {
    width: 240px;
    height: 260px;
    background-color: #f7f7f7;
    border-radius: 10px;
    margin: 20px;
    text-align: center;
    margin-top: 40px;
    .qrcode {
      padding-top: 30px;
      padding-left: 30px;
    }
  }
}

.icon_wrap {
  color: #bfbfbf;

  &:hover,
  &.ok {
    color: #ff5c00;

    span {
      color: #ff5c00;
    }
  }

  span {
    font-size: 12px;
    color: #666;
  }

  cursor: pointer;
  display: flex;
  align-items: center;

  .iconfont {
    margin-right: 5px;
  }
}
</style>
